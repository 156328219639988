import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { AreaSummaryType, BuildingTypeType, ChangeType, CohortByAreaByYearType, ForecastType, GrowthLimitParameters, ModifiedPopulationChangeByAreaType, ModifiedPopulationChangeType, MunicipalForecastSummaryType, ParameterQueryType } from "../../types";
import { populaApi } from "./apiSlice";
import { selectCurrentForecastId } from "./appSlice";

export const forecastAdapter = createEntityAdapter<ForecastType>()
const initialForecastState = forecastAdapter.getInitialState()

export const forecastSlice = createSlice({
    name: 'forecast',
    initialState: initialForecastState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            forecastApi.endpoints.getForecasts.matchFulfilled,
            (state, { payload }) => {
                forecastAdapter.setAll(state, payload)
            }
        )
    }
})

export const forecastApi = populaApi.injectEndpoints({
    endpoints: (builder) => ({
        getForecasts: builder.query<ForecastType[], void>({
            query: () => `forecast/forecast`,
            // @ts-ignore
            providesTags: response => {
                // @ts-ignore
                return response!.map(({ id }) => ({ type: 'Forecasts', id })).concat([{ type: 'Forecasts', id: 'ALL' }])
            }
        }),
        getForecast: builder.query<ForecastType, { forecastId: number }>({
            query: ({ forecastId }) => `forecast/forecast/${forecastId}`,
            providesTags: (_, __, { forecastId: id }) => [{ type: 'Forecasts', id }],
        }),
        lockForecast: builder.mutation<void, { forecastId: number }>({
            query: ({ forecastId }) => ({
                url: `forecast/forecast/lock/${forecastId}`,
                method: 'PUT',
            }),
            invalidatesTags: (_, __, { forecastId: id }) => [{ type: 'Forecasts', id }],
        }),
        addForecast: builder.mutation<ForecastType, { forecast: ForecastType }>({
            query: ({ forecast }) => ({
                url: `forecast/forecast`,
                method: 'POST',
                body: forecast,
            }),
            invalidatesTags: [{ type: 'Forecasts', id: 'ALL' }],
        }),
        editForecast: builder.mutation<ForecastType, { forecast: ForecastType }>({
            query: ({ forecast }) => ({
                url: `forecast/forecast/${forecast.id}`,
                method: 'PUT',
                body: forecast,
            }),
            invalidatesTags: (_, __, { forecast }) => [{ type: 'Forecasts', id: forecast.id }],
        }),
        updateMigrationForecast: builder.mutation<ForecastType, { parameter: ParameterQueryType, forecast: ForecastType }>({
            query: ({ forecast, ...parameter }) => ({
                url: `forecast/migration/${forecast.id}`,
                method: 'PATCH',
                body: { ...parameter },
            }),
            invalidatesTags: (_, __, args) => [
                { type: 'Forecasts', id: args.forecast.id },
                'MunicipalData', 'AreaData', 'MunicipalSummaryData', 'AreaSummaryData'
            ],
        }),
        deleteForecast: builder.mutation<void, { forecastId: number }>({
            query: ({ forecastId }) => ({
                url: `forecast/forecast/${forecastId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (_, __, { forecastId: id }) => [{ type: 'Forecasts', id }],
        }),
        getForecastMunicipalData: builder.query<{ data: CohortByAreaByYearType },{ forecastId:number }>({
            query: ({ forecastId }) => {
                return `forecast/forecast/municipal-data/${forecastId}`
            },
            providesTags: (_, __, { forecastId: id }) => [ { type: 'MunicipalData', id } ],
        }),
        getAreaDataForForecast: builder.query<CohortByAreaByYearType,{ forecastId:number }>({
            query: ({ forecastId }) => {
                return `forecast/forecast/area-data/${forecastId}`
            },
            providesTags: (_, __, { forecastId: id }) => [{ type: 'AreaData', id }],
        }),
        getAreaDataModifications: builder.query<ModifiedPopulationChangeByAreaType,{ forecastId:number }>({
            query: ({ forecastId }) => {
                return `forecast/forecast/area-data/${forecastId}/modifications`
            },
            providesTags: (_, __, { forecastId: id }) => [{ type: 'AreaData', id }],
        }),





        getForecastMunicipalSummaryData: builder.query<MunicipalForecastSummaryType,{ forecastId:number }>({
            query: ({ forecastId }) => {
                return `forecast/forecast/municipal-summary-data/${forecastId}`
            },
            providesTags: (_, __, { forecastId: id }) => [ { type: 'MunicipalSummaryData', id } ],
        }),
        getForecastAreaSummaryData: builder.query<AreaSummaryType,{ forecastId:number }>({
            query: ({ forecastId }) => {
                return `forecast/forecast/area-summary-data/${forecastId}`
            },
            providesTags: (_, __, { forecastId: id }) => [ { type: 'AreaSummaryData', id } ],
        }),
        modifyAreaData: builder.mutation<AreaSummaryType,{ forecastId:number, areaCode:string, modifications:ModifiedPopulationChangeType }>({
            query: ({ forecastId, areaCode, modifications }) => ({
                url: `forecast/forecast/area-data/${forecastId}/modifications`,
                method: 'PATCH',
                body: { [areaCode]: modifications },
            }),
            invalidatesTags: (_, __, { forecastId: id }) => [
                { type: 'AreaSummaryData', id },
                { type: 'AreaData', id },
                { type: 'MunicipalSummaryData', id },
                { type: 'MunicipalData', id },
            ],
        }),
        updateForecastChanges: builder.mutation<ChangeType[], { forecastId:number, changes:ChangeType[] }>({
            query: ({  forecastId, changes }) => ({
                url: `forecast/forecast/changes/${forecastId}`,
                method: 'PATCH',
                body: changes,
            }),
            invalidatesTags: (_, __, { forecastId: id }) => [
                { type: 'Forecasts', id },
                { type: 'MunicipalData', id },
                { type: 'MunicipalSummaryData', id },
                { type: 'AreaData', id }, // For now not used but for future use
                { type: 'AreaSummaryData', id },
            ],
        }),
        getBuildingTypes: builder.query<BuildingTypeType, { forecastId: number }>({
            query: ({ forecastId }) => {
                return `forecast/building-types/${forecastId}`
            },
            providesTags: (_, __, { forecastId: id }) => [{ type: 'BuildingTypes', id }],
        }),
        updateBuildingTypes: builder.mutation<BuildingTypeType, { forecastId: number, buildingTypes: BuildingTypeType }>({
            query: ({ forecastId, buildingTypes }) => ({
                url: `forecast/building-types/${forecastId}`,
                method: 'PUT',
                body: buildingTypes,
            }),
            invalidatesTags: (_, __, { forecastId: id }) => [
                { type: 'BuildingTypes', id },
                { type: 'AreaSummaryData', id },
                { type: 'AreaData', id },
                { type: 'MunicipalSummaryData', id },
                { type: 'MunicipalData', id },
            ],
        }),
        getNewConstrunctionRealizationPercentage: builder.query<number, { forecastId: number }>({
            query: ({ forecastId }) => {
                return `forecast/new-construction-realization-percentage/${forecastId}`
            },
            providesTags: (_, __, { forecastId: id }) => [{ type: 'NewConstructionRealizationPercentage', id }],
        }),
        updateNewConstrunctionRealizationPercentage: builder.mutation<number, { forecastId: number, percentage: number }>({
            query: ({ forecastId, percentage }) => ({
                url: `forecast/new-construction-realization-percentage/${forecastId}`,
                method: 'PUT',
                body: percentage,
            }),
            invalidatesTags: (_, __, { forecastId: id }) => [
                { type: 'NewConstructionRealizationPercentage', id },
                { type: 'AreaSummaryData', id },
                { type: 'AreaData', id },
                { type: 'MunicipalSummaryData', id },
                { type: 'MunicipalData', id },
            ],
        }),
        getGrowthLimitPercentages: builder.query<GrowthLimitParameters, { forecastId: number }>({
            query: ({ forecastId }) => `forecast/forecast/${forecastId}/growth-limits`,
            providesTags: (_, __, { forecastId: id }) => [{ type: 'GrowthLimitParameters', id }],
        }),
        updateGrowthLimitPercentages: builder.mutation<GrowthLimitParameters, { forecastId: number, data: GrowthLimitParameters }>({
            query: ({ forecastId, data }) => ({
                url: `forecast/forecast/${forecastId}/growth-limits`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: (_, __, { forecastId: id }) => [
                { type: 'GrowthLimitParameters', id },
                { type: 'AreaSummaryData', id },
                { type: 'AreaData', id },
                { type: 'MunicipalSummaryData', id },
                { type: 'MunicipalData', id },
            ],
        }),
    }),
})

export const {
    useGetForecastsQuery,
    useLockForecastMutation,
    useAddForecastMutation,
    useEditForecastMutation,
    useUpdateMigrationForecastMutation,
    useDeleteForecastMutation,
    useLazyGetForecastMunicipalDataQuery,
    useGetForecastAreaSummaryDataQuery,
    useGetForecastMunicipalSummaryDataQuery,
    useGetForecastMunicipalDataQuery,
    useLazyGetAreaDataForForecastQuery,
    useModifyAreaDataMutation,
    useGetAreaDataModificationsQuery,
    useUpdateForecastChangesMutation,
    useGetBuildingTypesQuery,
    useUpdateBuildingTypesMutation,
    useGetNewConstrunctionRealizationPercentageQuery,
    useUpdateNewConstrunctionRealizationPercentageMutation,
    useGetGrowthLimitPercentagesQuery,
    useUpdateGrowthLimitPercentagesMutation,
} = forecastApi

export const {
    selectAll: selectForecasts,
    selectById: selectForecastById
} = forecastAdapter.getSelectors((state: RootState) => state.forecast)

export const selectBaseParameterMethods = createSelector(
    selectCurrentForecastId,
    selectForecastById,
    (id, forecast) => forecast?.municipalMethods
)

export const selectAllParametersInitialized = createSelector(
    selectBaseParameterMethods,
    (municipalMethods) => {
        if (!municipalMethods) return false
        const methods = Object.values(municipalMethods)
        return methods.length === 6 && methods.every(method => method !== undefined)
    }
)

export const selectCurrentForecast = createSelector(
    selectCurrentForecastId,
    selectForecasts,
    (current, forecasts) => {
        const b =  forecasts.find(forecast => forecast.id === current?.id)
        return b ? { ...b, selected: current?.selected } : b
    }
)