import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import { store } from './app/store';
import { msalConfig } from './authConfig';
import './index.css';
import reportWebVitals from './reportWebVitals';

import { ThemeProvider } from '@emotion/react';
import { CssBaseline, createTheme } from '@mui/material';
import { LicenseInfo } from '@mui/x-data-grid-premium';
import './i18n';

LicenseInfo.setLicenseKey('43995bdba792976f0f11ea29e6f60a97Tz04MDk0OCxFPTE3MzQ3ODM3NDkwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y')

const theme = createTheme({
  typography: {
    fontFamily: 'Open Sans, sans-serif',
    h1: {
      fontSize: '2.5rem',
      color: 'white',
      fontWeight: 700,
    },
    h2: {
      fontSize: '1.5rem',
      color: 'black',
    },
    h3: {
      fontSize: '2rem',
      color: 'black',
      fontWeight: 400,
    },
    h4: {
      fontSize: '1.12rem',
      color: 'black',
      fontWeight: 400,
    },
  },
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
        }
      }
    },
    MuiStack: {
      styleOverrides: {
        root: {
          width: '100%',
          '&.popula-ai-navigation': {
            width: '2.5rem',
            padding: '0.5em',
            backgroundColor: '#C4E8FA',
            overflow: 'hidden',
            justifyContent: 'flex-start',
            minHeight: '100%',
          }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: 'black',
            fontWeight: 500,
          },
          '&.MuiTabs-indicator': {
            display: 'none',
          }
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: '#C4E8FA',
          '&.MuiTabs-indicator': {
            display: 'none',
          }
        },
      },
    },
    MuiGrid2: {
      styleOverrides: {
        root: ({ ownerState }) => {
          return !ownerState.container ? { padding: '0.5rem' } : {}
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        h3 {
          color: black;
          font-size: 1.5rem;
        }
        .MuiBox-root {
          width: 100%;
        }
        .popula-ai-main-app-container {
          padding: 0.5rem;
        }
        .popula-ai-header {
          background-color: #1F4054;
          color: white;
        }
        .popula-ai-subheader {
          background-color: #70C4F2;
          color: black;
        }
      `,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const msalInstance = new PublicClientApplication(msalConfig);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <MsalProvider instance={msalInstance}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </MsalProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
