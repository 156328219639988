import { TextField, TextFieldProps } from "@mui/material"

export type NumberFieldProps = TextFieldProps & {
    onChange: (value: any) => void
}
export const NumberField = ({ onChange, ...props }: NumberFieldProps) => {

    const isLegalNumber = (value: string) => {
        return !isNaN(Number(value)) || (value.startsWith('-') && !isNaN(Number(value.slice(1))))
    }

    const checkAndSetValue = ({ target }: React.ChangeEvent<HTMLInputElement>) => {

        const { value } = target
        const valueStr = String(value)
        
        if (!valueStr || valueStr.trim() === '') {
            onChange(valueStr)
            return
        }

        const valueWithoutCommas = valueStr.replace(',', '.')

        if (isLegalNumber(valueWithoutCommas)) {
            onChange(valueWithoutCommas)
        }
    }

    return (
        <TextField onChange={checkAndSetValue} {...props} />
    )
}
