import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import { GridColDef, GridRowSelectionModel } from "@mui/x-data-grid-premium"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { openDrawer, selectDrawerOpen } from "../../common/uiSlice"
import { DIYDrawer } from "../../components/Drawer"
import { DataGridComponent } from "../../components/Tables"
import { AreaPopulationType } from "../../types"
import { selectCurrentForecast, useGetAreaDataModificationsQuery, useGetForecastAreaSummaryDataQuery } from "../apis/forecastApi"
import { AreaCodeView, ComparedToType } from "./AreaCodeView"
import AreaTotalsView from "./AreaTotalsView"
import EditAreaParameters, { AreaCodeNameType } from "./EditAreaParameters"

export const migrationForecastColumnDefs: GridColDef[] = [
    { field: 'areaCode' },
    { field: 'name', headerName: '', width: 170 },
    {
        field: 'netMigration',
        width: 140,
        editable: false,
    },
    {
        field: 'netIntraMunicipalMigration',
        width: 140,
        editable: false,
    },
    {
        field: 'netInterMunicipalMigration',
        width: 140,
        editable: false,
    },
    {
        field: 'births',
        width: 140,
        editable: false,
    },
    {
        field: 'deaths',
        width: 140,
        editable: false,
    },
    {
        field: 'change',
        width: 140,
        editable: false,
        renderCell({ value }) {
            
            if (!value) return ''
            if (value === 0) return value
            
            return <b>{value > 0 ? '+' + value : value} %</b>
        },
    },
]

type AreaTableProps = {
    editMode: boolean
    setEditMode: (editMode: boolean) => void,
    setDialogProps: (dialogProps: { title: string, message: string }) => void
}

const AreaTable = ({ editMode, setEditMode, setDialogProps }: AreaTableProps) => {

    const { t } = useTranslation()

    const dispatch = useAppDispatch()

    const forecast = useAppSelector(selectCurrentForecast)
    const drawerOpen = useAppSelector(selectDrawerOpen)

    const { data } = useGetForecastAreaSummaryDataQuery({ forecastId: forecast!.id! })
    const { data: modifications } = useGetAreaDataModificationsQuery({ forecastId: forecast!.id! })

    const [drawerView, setDrawerView] = useState<string>('-1')
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([])
    const [selectedAreas, setSelectedAreas] = useState<AreaCodeNameType[]>([])

    const { rows, population, areas, meta, populationChangeComponents } = useMemo(() => {

        if (data && modifications) {

            const { populationChangeComponents, areas, population, meta } = data

            const {
                emigrationAvg,
                immigrationAvg,
                intraMunicipalMigrationInAvg,
                intraMunicipalMigrationOutAvg,
                interMunicipalMigrationInAvg,
                interMunicipalMigrationOutAvg,
                birthsAvg,
                deathsAvg,
            } = populationChangeComponents

            const netMigration = Math.round(Object.values(immigrationAvg).reduce((a, b) => a + b, 0) - Object.values(emigrationAvg).reduce((a, b) => a + b, 0))
            const netIntraMunicipalMigration = Math.round(Object.values(intraMunicipalMigrationInAvg).reduce((a, b) => a + b, 0) - Object.values(intraMunicipalMigrationOutAvg).reduce((a, b) => a + b, 0))
            const netInterMunicipalMigration = Math.round(Object.values(interMunicipalMigrationInAvg).reduce((a, b) => a + b, 0) - Object.values(interMunicipalMigrationOutAvg).reduce((a, b) => a + b, 0))
            const births = Math.round(Object.values(birthsAvg).reduce((a, b) => a + b, 0))
            const deaths = Math.round(Object.values(deathsAvg).reduce((a, b) => a + b, 0))

            const { startYear, endYear } = meta

            const [ startPopulation, endPopulation ] = [
                Object.values(population).reduce((a, b) => a + b[startYear], 0),
                Object.values(population).reduce((a, b) => a + b[endYear], 0),
            ]

            const aggregatedRow = {
                id: -1,
                name: 'Yhteensä',
                netMigration,
                netIntraMunicipalMigration,
                netInterMunicipalMigration,
                births,
                deaths,
                change: Math.round((endPopulation - startPopulation) / startPopulation * 100)
            }

            return {
                rows: [aggregatedRow].concat(Object.entries(data.areas).map(([areaCode, name], idx) => {

                    const netMigration = Math.round(immigrationAvg[areaCode] - emigrationAvg[areaCode])
                    const netIntraMunicipalMigration = Math.round(intraMunicipalMigrationInAvg[areaCode] - intraMunicipalMigrationOutAvg[areaCode])
                    const netInterMunicipalMigration = Math.round(interMunicipalMigrationInAvg[areaCode] - interMunicipalMigrationOutAvg[areaCode])
                    const births = Math.round(birthsAvg[areaCode])
                    const deaths = Math.round(deathsAvg[areaCode])

                    const [ startPopulation, endPopulation ] = [ population[areaCode][startYear], population[areaCode][endYear] ]

                    const changePercentage = Math.round((endPopulation - startPopulation) / startPopulation * 100)
                    const change = isNaN(changePercentage) ? 0 : changePercentage

                    return {
                        id: idx,
                        areaCode: areaCode,
                        name,
                        netMigration,
                        netIntraMunicipalMigration,
                        netInterMunicipalMigration,
                        births,
                        deaths,
                        change,
                    }
                })),
                population,
                areas,
                meta,
                populationChangeComponents,
                modifications,
            }
        }
        return {
            rows: [],
            population: {} as AreaPopulationType,
            populationChangeComponents: {} as any,
            modifications: {} as any,
            areas: {} as { [id: string]: string },
            meta: {} as { [id: string]: string },
            comparedTo: {} as { [id: string]: ComparedToType },
        }
    }, [data, modifications])

    useEffect(() => {
        setSelectedAreas((rowSelectionModel as number[]).map(r => { const { areaCode, name: areaName } = rows[r + 1] as any; return { areaCode, areaName } }))
    }, [ rowSelectionModel, rows ])

    const onDeleteArea = (areaCode: string) => {
        setRowSelectionModel((rowSelectionModel as number[]).filter(r => (rows[r + 1] as any).areaCode !== areaCode))
    }

    return (
        <Grid2 container>
            <Grid2 xs={8} padding={0} style={{maxHeight: '100vh', overflow: 'auto'}}>
                <DataGridComponent
                    sx={{
                        position: 'relative',
                        width: 'inherit',
                        border: 'none',
                        "& .MuiDataGrid-columnHeaderTitle": {
                            whiteSpace: "normal",
                            lineHeight: "normal"
                          },
                          "& .MuiDataGrid-columnHeader": {
                            // Forced to use important since overriding inline styles
                            height: "unset !important"
                          },
                          "& .MuiDataGrid-columnHeaders": {
                            // Forced to use important since overriding inline styles
                            maxHeight: "168px !important"
                          }
                    }}
                    columns={
                        migrationForecastColumnDefs.map((column) => ({
                            ...column,
                            headerName: t(`areaView.tableFields.${column.field}`),
                        }))
                    }
                    rows={rows}
                    getRowClassName={
                        (params) => {
                            if (params.row.id === -1) return `super-app-theme--FirstRow`
                            else if (Object.keys(modifications!).includes(params.row.areaCode)) return `super-app-theme--BoldRow`
                            else return ''
                        }
                    }
                    pinnedRows={{
                        top: [rows[0] || { id: -1 }]
                    }}
                    onRowClick={({ row }) => {
                        const { areaCode } = row
                        // if (!scenario || !scenario.selected) setEditMode(false)
                        setDrawerView(areaCode || '-1')
                        !drawerOpen && dispatch(openDrawer())
                    }}
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                areaCode: false,
                            },
                        },
                    }}
                    rowSelectionModel={rowSelectionModel}
                    onRowSelectionModelChange={setRowSelectionModel}
                />
            </Grid2>
            <Grid2 xs={4}>
            {
                    drawerView === '-1' ? (
                        <AreaTotalsView />
                    ) : (
                        !editMode ?
                            <AreaCodeView
                                areaName={areas[drawerView!]}
                                areaCode={drawerView!}
                                population={population}
                                populationChange={populationChangeComponents}
                                startYear={meta.startYear as number}
                                endYear={meta.endYear as number}
                                setDialogProps={setDialogProps}
                                modifications={modifications || {}}
                                editable={true}
                            />
                            :
                            <DIYDrawer>
                                <EditAreaParameters areas={selectedAreas} onDeleteArea={onDeleteArea} onClose={() => setEditMode(false)} />
                            </DIYDrawer>
                    )
                }            </Grid2>
        </Grid2>
    )
}

export default AreaTable